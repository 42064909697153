import Http from './http'
import Qs from 'querystring'

// 新增获取mobiletype 2021-11-22 /users/getMobileType
export function getMobileType() {
    return Http({
        url: '/users/getMobileType',
        method: 'post'
    })
}

// 1.获取用户信息（登录之后调用）
export function getUserAccount(params) {
    return Http({
        url: '/userinfo/getUserAccount',
        method: 'get',
        params
    })
}
// 判断用户名是否违规(1用户名违规；2头像违规)
export function userExamine(params) {
    return Http({
        url: '/userinfo/userExamine',
        method: 'get',
        params
    })
}
// 注册，必传: mobile , password, code
export function register(params) {
    return Http({
        url: '/users/register',
        method: 'get',
        params
    })
}

// 获取短信验证码，必传: mobile, type(1 验证码 2注册码 3重置密码)
export function sendMsg(params) {
    return Http({
        url: '/users/sendMsg',
        method: 'get',
        params
    })
}

// 登录用户通过密码，必传: username, password
export function loginByPassword(data) {
    return Http({
        url: '/users/loginByPassword',
        method: 'post',
        data: Qs.stringify(data)
    })
}

// 登录用户通过验证码，必传: username, code
export function loginByCode(data) {
    return Http({
        url: '/users/loginByCode',
        method: 'post',
        data: Qs.stringify(data)
    })
}
// 登出
export function logout() {
    return Http({
        url: '/users/logout',
        method: 'get'
    })
}

// 修改密码 短信验证
export function verificateCode(data) {
    return Http({
        url: '/users/verificateCode',
        method: 'post',
        data: Qs.stringify(data)
    })
}

// 修改密码 必传: username，password，code
export function repassword(data) {
    return Http({
        url: '/users/repassword',
        method: 'post',
        data: Qs.stringify(data)
    })
}

// 1.获取系列用于下拉筛选
export function getSeries() {
    return Http({
        url: '/home/getSeries',
        method: 'get'
    })
}

// 首页
export function homeIndex() {
    return Http({
        url: '/home/index',
        method: 'get'
    })
}
