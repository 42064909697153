<template>
    <div class="user-wrapper">
        <template v-if="userInfoObj">
            <router-link to="/my" class="user-link">{{ userInfoObj.username }}</router-link>
            <router-link to="/my/order/1" class="user-link">我的订单</router-link>
            <router-link to="/my/message" class="user-link">我的消息</router-link>
        </template>
        <template v-else>
            <router-link to="/register" class="user-link">注册</router-link>
            <router-link to="/login" class="user-link">登录</router-link>
        </template>
    </div>
</template>
<script>
import { logout } from '@api'
export default {
    name: 'UserInfoSimple',
    props: ['userInfoObj'],
    data() {
        return {
            username: ''
        }
    },
    created() {
        const userInfo = this.$bus.$on('userInfo')
        this.username = userInfo ? userInfo.username : ''
    }
}
</script>
<style lang="scss" scoped>
    .user-wrapper {
        @include inlineBlock;
        padding: 0 20px;
        height: 100%;
        text-align: right;
        .item {
            @include inlineBlock;
            width: 50px;
            height: 50px;
            padding: 13px;
        }
    }
    .avatar-box {
        position: relative;
        .avatar {
            display: block;
            width: 100%;
            height: 100%;
        }
        .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            text-align: center;
            color: $white;
            background-color: $red;
        }
    }
    .user-link {
        @include inlineBlock();
        padding: 0 25px;
        margin: 10px 0;
        line-height: 30px;
        border-left: 1px solid $gray;
        &:first-child {
            border: none;
        }
    }
    .user-popover-item {
        display: block;
        padding: 5px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .user-info-simple {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        text-align: right;
        .user-link {
            @include inlineBlock();
            padding: 0 20px;
            margin: 5px 0;
            line-height: 20px;
            border-left: 1px solid $gray;
            color: $light;
            &:first-child {
                border: none;
            }
            &:hover {
                color: $red;
            }
        }
    }
</style>
