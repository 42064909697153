// 常规列表混入
export default {
    data() {
        return {
            reg: {
                mailbox: /^\w+@\w+\.(cn|com)$/, // 邮箱, /数字字母下划线@数字字母下划线.cn|com/
                mailboxPrompt: '请输入正确的邮箱格式',
                number: /^\d+$/, // 是否是数字
                tel: /^[1][3,4,5,7,8][0-9]{9}$/, // 手机
                telPrompt: '请输入正确的手机号格式',
                doubleReg: /^\d+(\.\d+)?$/, // 小数验证
                doubleRegPrompt: '只允许输入数字(包括小数)',
                fax: /^(\d{3,4}-)?\d{7,8}$/, // 传真校验
                faxPrompt: '请输入正确的传真格式',
                noCn: /^[^\u4e00-\u9fa5]+$/, // 不允许中文
                noCnPrompt: '不允许输入中文',
                numLength: /^[A-Za-z_0-9-]{1,50}$/, // 只允许输入英文(半角), 数字, 下划线, 横线, 且长度不超过50
                lengthPrompt: '只允许输入英文(半角)，数字，下划线, 横线且字符长度不允许超过50',
                positiveInteger: /^[1-9]\d*$/, // 只允许输入正整数（不包括0)
                positivePrompt: '只允许输入大于0的正整数',
                en: /^[A-Za-z]+$/, // 只允许英文
                enPrompt: '只允许输入英文(半角)',
                enUpperNumberline: /^[A-Z_0-9]+$/,
                enUpperNumberlinePrompt: '只允许输入大写英文(半角)，数字，下划线',
                enCnNumber: /^[a-zA-Z0-9\u4e00-\u9fa5]+$/,
                enCnNumberPrompt: '只允许输入中文，英文(半角)，数字',
                noSpace: /^[\s\S]*.*[^\s][\s\S]*$/ // 非空校验
            },
            countDownTimeMax: 60, // 倒计时60秒
            loading: false // 加载页面
        }
    },
    methods: {
        // 重置统一
        resetForm() {
            for (const key in this.form) {
                this.form[key] = ''
            }
        },
        // 限制输入数字
        numberInputChange(value) {
            this[value] = this[value].replace(/[^\d]/g, '')
        },
        // 倒计时
        countDown(key = 'COUNTDOWN_LOGIN') {
            this.timer = setInterval(_ => {
                // console.log('this.countDownTime', this.countDownTime)
                if (this.countDownTime > 0 && this.countDownTime <= 60) {
                    this.countDownDisabled = true
                    this.countDownTime--
                } else {
                    this.countDownDisabled = false
                    this.countDownTime = 0
                    // 清除定时器
                    if (this.timer) window.clearInterval(this.timer)
                    // 清除缓存值
                    localStorage.clear(key)
                }
                console.log('this.countDownTime countDown', this.countDownTime)
                // 记录当前时间更新缓存
                // localStorage.setItem(key, new Date().getTime())
            }, 1000)
        }
    }
}
